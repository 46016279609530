import { Controller } from 'stimulus'

/**
 * Redirect to a Stripe checkout page
 * To use this, you'll need your Stripe publishable key needed by Stripe.js as well as
 * a session ID retrieved from Stripe's API.
 *
 * @param {string} publicKey - The Stripe public key required to make this request
 * @param {string} sessionId - The session ID retrieved from Stripe
 * @param {integer} delay - Redirection will be delayed by this many milliseconds. If not present, redirection is immediate.
 *
 * @see https://support.stripe.com/questions/locate-api-keys-in-the-dashboard
 * @see https://stripe.com/docs/api/checkout/sessions/create
 */
export default class extends Controller {
  static targets = [ 'error' ]

  connect() {
    const delay = Math.abs( parseInt(this.data.get('delay')) ) || 0
    if(delay == 0) {
      this.checkout()
    } else {
      setTimeout(this.checkout.bind(this), delay)
    }
  }

  checkout() {
    const stripe = Stripe(this.data.get('publicKey'));
    stripe.redirectToCheckout({
      sessionId: this.data.get('sessionId')
    }).then(function (result) {
      if(this.errorTarget) {
        this.errorTarget.innerHTML = result.error.message
      }
    });
  }
}
