import { Controller } from 'stimulus'

/**
 * Restrict the number of characters that can be typed into a number field.
 *
 * HTML only restricts characters via maxlength for certain types of fields, such as input and tel.
 * Notably, maxlength is not supported on input fields of type "number". Number fields do support
 * min and max attributes, however these are only applicable when clicking the field's up/down
 * buttons to change the value, not for keyboard input.
 *
 * This controller restricts the number of characters on number fields (and otherwise) via keyboard
 * input by leveraging the canonical maxlength property.
 *
 * Usage:
 * <input type="number" maxlength="3" data-controller="max-length" />
 *
 * This example restricts keyboard input to a max of three characters (e.g. 000, 999, but not 1234
 * or 9999).
 */
export default class extends Controller {
  connect() {
    this.handler = this.restrictChars.bind(this)
    this.element.addEventListener('input', this.handler)
  }

  disconnect() {
    this.element.removeEventListener('input', this.handler)
  }

  restrictChars(evt) {
    if (evt.target.value.length > this.element.maxLength) {
      evt.target.value = evt.target.value.slice(0, this.element.maxLength)
    }
  }
}
