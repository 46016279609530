import { Controller } from 'stimulus'

/**
 * Inform StepsController (or other interested parties) to update the current step
 */
export default class extends Controller {
  connect() {
    let updateAnchor = true
    if(this.data.get('updateAnchor') === 'false') {
      updateAnchor = false
    }

    const eventDetails = {
      detail: {
        stepName: this.data.get('name'),
        updateAnchor: updateAnchor
      }
    }
    window.dispatchEvent(new CustomEvent('stepChange', eventDetails))
  }
}
