import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.threshold = parseInt(this.data.get('threshold'))
    this.scrollListener = this.update.bind(this)
    window.addEventListener('scroll', this.scrollListener, {passive: true})
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollListener, {passive: true})
  }

  update() {
    if(window.scrollY > this.threshold) {
      if(this.active !== true) {

        this.active = true
        this.element.classList.add('scrolled')
      }
    } else {
      if(this.active !== false) {
        this.active = false
        this.element.classList.remove('scrolled')
      }
    }
  }
}
