import { Controller } from "stimulus"
import screenfull from "screenfull"

export default class extends Controller {
  static targets = [ "button" ]

  connect() {
    this.hideFullScreenFromUnsupportedBrowsers()
  }

  hideFullScreenFromUnsupportedBrowsers() {
    if(!screenfull.isEnabled) {
      this.buttonTarget.style.display = "none"
    }
  }

  toggleFullScreen() {
    if(screenfull.isEnabled) {
      screenfull.toggle()
    }
  }
}
