import { Controller } from 'stimulus'
import { DirectUpload } from "@rails/activestorage/src"
import Rails from "@rails/ujs"


export default class extends Controller {
  static targets = ['form', 'progress']


  progress(event) {
    if(this.progressTarget) {
      this.progressTarget.value = event.detail.progress
    }
  }

  upload() {
    this.element.classList.remove('is-pending-upload')
    if(this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit')
    }
  }

}
