import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if(this.data.get('strip') === 'true') {
      history.replaceState({}, '', window.location.pathname)
    } else {
      window.location.hash = this.data.get('name')
    }
  }
}
