import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'code', 'load' ]

  connect() {
    this.codeTarget.select()
  }

  input(evt) {
    this.loadTarget.classList.remove('is-verified', 'is-not-verified')
    this.codeTarget.value = this.codeTarget.value.trim()
    if(this.codeTarget.value.length >=6) {
      this.codeTarget.value = this.codeTarget.value.substr(0,6)
      Rails.fire(this.element, 'submit')
    }
  }

  submit() {
    this.codeTarget.blur()
    this.loadTarget.classList.add('is-loading')
  }

  success() {
    this.loadTarget.classList.remove('is-loading')
    this.loadTarget.classList.add('is-verified')
  }

  error() {
    this.loadTarget.classList.remove('is-loading')
    this.loadTarget.classList.add('is-not-verified')
    this.codeTarget.select()
  }
}
