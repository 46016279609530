import { Controller } from 'stimulus'

/**
 * Allow nodes to be part of a group in which a single node can be selected
 *
 * A single node can be toggled on and off, but selecting node B while node A is selected
 * will deslected node A.
 */
export default class extends Controller {
  static targets = ['item']

  disconnect() {
    this.deselect()
  }

  toggle(evt) {
    if(evt.target.closest('[data-group-clickable="false"')) {
      return
    }

    const item = this.itemFromTarget(evt.target)
    if(this.isSelected(item)) {
      this.deselect()
    } else {
      this.select(item)
    }
  }

  select(item) {
    item.classList.add(this.selectionClass)
    item.classList.remove(this.notSelectionClass)

    this.itemTargets.forEach((target) => {
      if(target != item) {
        target.classList.remove(this.selectionClass)
        target.classList.add(this.notSelectionClass)
      }
    })
  }

  deselect() {
    this.itemTargets.forEach((target) => {
      target.classList.remove(this.selectionClass, this.notSelectionClass)
    })
  }


  get selectionClass() {
    return 'is-group-selection'
  }

  get notSelectionClass() {
    return 'is-not-group-selection'
  }

  itemFromTarget(target) {
    return target.closest('[data-group-target*="item"]')
  }

  isSelected(item) {
    return item.classList.contains(this.selectionClass)
  }
}
