import { Controller } from 'stimulus'

/**
 * Perform basic functionality in response to simple remote form actions.
 *
 * For instance, a reusable avatar editor can call a controller that only handles
 * editing/deleting avatars via a remote request, and then reload the entire page on
 * success to render the new avatar, rather than rendering and replacing a single piece of
 * the page.
 *
 * Dynamically replacing content in place can certainly be great, but this is a dead simple way
 * to quickly work with remote forms.
 */
export default class extends Controller {
  reload() {
    location.reload()
  }

  error() {

  }
}
