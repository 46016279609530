import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'step' ]

  connect() {
    this.stepChangeListener = this.handleStepChange.bind(this)
    window.addEventListener('stepChange', this.stepChangeListener, true)
  }

  disconnect() {
    window.removeEventListener('stepChange', this.stepChangeListener, true)
  }

  handleStepChange(evt) {
    for(const target of this.stepTargets) {
      if(target.dataset.stepName == evt.detail.stepName) {
        target.classList.add('is-active')
        if(evt.detail.updateAnchor) {
          window.location.hash = evt.detail.stepName
        }
      } else {
        target.classList.remove('is-active')
      }
    }
  }
}
