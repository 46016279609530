import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'email', 'result', 'getCodeButton' ]

  // TODO: If user changes email after submitting, hide code entry panel

  submit() {
    this.getCodeButtonTarget.disabled = 'disabled'
    this.getCodeButtonTarget.classList.add('is-loading')
    this.emailTarget.blur()
    this.resultTarget.innerHTML = ''
  }

  response(evt) {
    this.getCodeButtonTarget.classList.remove('is-loading', 'is-primary')

    // Prevent a flash from the animation changing from is-link + disabled to
    // is-link to not is-link (light blue to dark blue to  white). This helps
    // achieve a light blue (disabled) to white transition.
    setTimeout(() => {
      this.getCodeButtonTarget.disabled = null
    }, 100)

    const [xhr, status] = evt.detail
    this.resultTarget.innerHTML = xhr.response
  }
}
