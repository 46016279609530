import { Controller } from 'stimulus'

/**
 * Dispatches an event and includes any data-* tags on the controller element.
 * This allows interactions on various elements to dispatch data-containing events
 * for notifying other components without requiring any unique code.
 *
 * Any data-* tags set on the controller element will be included in CustomEvent.detail.data.
 *
 * Attributes:
 * - type: A string representing the event type to be dispatched. This corresponds to Event.type.
 *
 * Example:
 * For instance, if clicking on a user should fire a "user.selection" event that notifies
 * a UserDetails component elsewhere on the page, it could look like:
 *
 * <ul>
 *   <li data-controller="dispatcher"
 *       data-dispatcher-type="user.selection"
 *       data-action="click->dispatcher#dispatch"
 *       data-user-id="1"
 *   >
 *     Jeff Carnes
 *   </li>
 *   <li data-controller="dispatcher"
 *       data-dispatcher-type="user.selection"
 *       data-action="click->dispatcher#dispatch"
 *       data-user-id="2"
 *     Roberta Marley
 *   </li>
 * </ul>
 *
 * In this example, the user IDs are available in the dispatched event via event.detail.data.userId.
 */
export default class extends Controller {
  dispatch(evt) {
    const event = new CustomEvent(
      this.data.get('type'),
      {
        detail: {
          data: evt.currentTarget.dataset
        }
      }
    )
    window.dispatchEvent(event)
  }
}
