import { Controller } from 'stimulus'

export default class extends Controller {
  disconnect() {
    if(this.loadingTimeout) {
      clearTimeout(this.loadingTimeout)
    }
  }

  loading(evt) {
    this.loadingTimeout = setTimeout(() => {
      this.element.classList.add('is-loading')
    }, 250)
  }

  stopLoading(evt) {
    if(!evt.detail[0]) {
      clearTimeout(this.loadingTimeout)
      this.element.classList.remove('is-loading')
    }
  }
}
