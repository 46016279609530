import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'codeButton' ]

  success() {
    this.disable()
    this.codeButtonTarget.style.display = 'none'
  }

  enable() {
    for(const target of this.inputTargets) {
      target.disabled = false
    }
  }

  disable() {
    for(const target of this.inputTargets) {
      target.disabled = true
    }
  }

  static() {
    for(const target of this.inputTargets) {
      target.classList.add('is-static')
    }
  }
}
