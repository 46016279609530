import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "target" ]

  connect() {
    if(!this.hasTargetTarget) {
      return
    }

    this.offsetX = parseInt(this.data.get('offset-x')) || 0
    this.offsetY = parseInt(this.data.get('offset-y')) || 0

    this.resizeListener = this.update.bind(this)
    window.addEventListener('resize', this.resizeListener, {passive: true})

    this.update()
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeListener, {passive: true})
  }

  update() {
    // Avoid unnecessary updates if the window hasn't changed width
    const windowWidth = window.innerWidth;
    if(windowWidth == this.lastWindowWidth) {
      return
    } else {
      this.lastWindowWidth = windowWidth
    }

    const boundingRect = this.targetTarget.getBoundingClientRect()
    const x = boundingRect.left + (boundingRect.right - boundingRect.left) / 2
    const y = boundingRect.top + (boundingRect.bottom - boundingRect.top) / 2

    const bgSizes = window.getComputedStyle(document.body).backgroundSize.split(' ')
    const bgWidth = parseInt(bgSizes[0])
    const bgHeight = parseInt(bgSizes[1])

    document.body.style.backgroundPositionX = x - (bgWidth/2) + window.scrollX + this.offsetX + "px"
    document.body.style.backgroundPositionY = y - (bgHeight/2) + window.scrollY + this.offsetY + "px"
  }
}
