import { Controller } from 'stimulus'

/**
 * Easily focus or blur an element in response to an event.
 * The controller element (e.g. the element on which a controller attribute is declared) is
 * always the target of the focus/blur action.
 *
 * Example:
 * <input type="text" data-controller="focus" data-action="orientationchange@window->focus#focus" />
 */
export default class extends Controller {
  focus() {
    if(!this.delay) {
      this.element.focus()
    } else {
      setTimeout(() => { this.element.focus() }, this.delay)
    }
  }

  blur() {
    if(!this.delay) {
      this.element.blur()
    } else {
      setTimeout(() => { this.element.blur() }, this.delay)
    }
  }

  get delay() {
    if(this.data.get('delay')) {
      return parseInt(this.data.get('delay'))
    } else {
      return null
    }
  }
}
