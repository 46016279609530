import { confetti } from 'lib/confetti'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.duration = parseInt(this.data.get('duration')) || 0

    if(!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      // Only autostart if it's not a preview
      if(this.data.get('autoplay') == 'true') {
        this.start()
      }
    }
  }

  disconnect() {
    this.remove()
  }

  start() {
    confetti.start(this.duration)
  }

  stop() {
    confetti.stop()
  }

  toggle() {
    confetti.toggle()
  }

  remove() {
    confetti.remove()
    const confettiElement = document.getElementById('confetti-canvas')
    if(confettiElement) {
      confettiElement.remove()
    }
  }
}

