import { Controller } from "stimulus"
import screenfull from "screenfull"

export default class extends Controller {
  static targets = [ "message", "fullScreen" ]

  initialize() {
    this.setMessageFontSizes()
  }

  connect() {
    for(let i=1; i < this.messageTargets.length; i++) {
      this.messageTargets[i].style.display = "none"
    }

    this.currentIndex = 0;
    this.interval = setInterval(this.rotate.bind(this), 5000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  rotate() {
    const currentMessage = this.messageTargets[this.currentIndex]
    if(this.currentIndex + 1 >= this.messageTargets.length) {
      this.currentIndex = 0
    } else {
      this.currentIndex++
    }
    const nextMessage = this.messageTargets[this.currentIndex]

    currentMessage.style.display = "none"
    nextMessage.style.display = "block"
  }

  setMessageFontSizes() {
    this.messageTargets.forEach((message) => {
      const messageBody = message.querySelector(".message-body")
      const messageLength = messageBody.innerHTML.length
      // Use bigger font size for smaller messages, smaller font size for longer messages
      const fontSize = 15 - Math.log(messageLength**2) + "vw"
      messageBody.style.fontSize = fontSize
    })
  }

  keyboardClose(evt) {
    if(evt.key == "Escape") {
      this.close()
    }
  }

  close(evt) {
    screenfull.exit()
      .then(() => Turbolinks.visit(this.data.get('closeUrl')))
  }
}
