import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'loader' ]

  connect() {
    this.loadingClass = this.data.get('loading-class') || 'is-loading'
  }

  startLoading() {
    for(const target of this.loaderTargets) {
      target.classList.add(this.loadingClass)
    }
  }

  stopLoading() {
    for(const target of this.loaderTargets) {
      target.classList.remove(this.loadingClass)
    }
  }
}
