import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: 'https://01bd0b0fbd56408e815079a4f2854f3e@o488689.ingest.sentry.io/5549525',
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  tracesSampleRate: 1.0,
});

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Forward Stimulus exceptions to Sentry
const defaultErrorHandler = application.handleError;
const sentryErrorHandler = (err) => {
  defaultErrorHandler(err);
  Sentry.captureException(err);
};

application.handleError = sentryErrorHandler;
