import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'results' ]

  /**
   * Clean up the input to be a (mostly) valid email local part
   */
  sanitize() {
    let value = this.inputTarget.value
    // Strip off any email domain
    value = value.replace(/@.*$/, '')
    // Strip off generally disallowed characters in the email local part.
    // "Generally because, well, it's complicated.
    // https://en.wikipedia.org/wiki/Email_address
    value = value.replace(/[^a-zA-Z0-9!#\$%&'\*\+-\/=\?\^_`{|}~]/g, '')

    if(this.inputTarget.value != value) {
      this.inputTarget.value = value
    }

    this.resize()
  }

  /**
   * Submit a search query
   * @param evt
   */
  query(evt) {
    // Don't process spaces. They interfere with results navigation!
    if(evt.keyCode == 32) {
      evt.preventDefault()
      return
    }

    if(this.inputTarget.value == this.data.get('searchTerm')) {
      return
    }

    let searchTerm = this.inputTarget.value
    this.data.set('searchTerm', searchTerm)

    if(searchTerm.length == 0) {
      this.closeSearchResults()
      return
    }

    const url = `${this.data.get('url')}?q=${searchTerm}`
    fetch(url).then((response) => {
      response.text().then((text) => {
        if (searchTerm == this.data.get('searchTerm')) {
          this.showSearchResults(text)
        }
      })
    })
  }

  /**
   * Display search results
   * @param text
   */
  showSearchResults(text) {
    this.resultsTarget.innerHTML = text
    this.openSearchResults()
  }

  /**
   * Resize the search field to fit the size of the input
   * This results in a field that's always approximately the size of the input,
   * keeping it close to the domain name portion of the display.
   */
  resize() {
    let inputSize = Math.max(this.inputTarget.value.length + 4, 12)
    inputSize = Math.min(inputSize, 64)
    this.inputTarget.size = inputSize
  }

  /**
   * Handle keyboard navigation within the search input field
   * @param evt
   */
  navigate(evt) {
    // The current set of actions only apply when the search results window is open.
    // If it's not, we can safely abort.
    if(!this.isSearchOpen) { return }


    if(evt.key == 'Escape' || evt.key == 'Esc' || evt.keyCode == 27) {
      this.closeSearchResults()
    }

    if(evt.key == 'ArrowDown' || evt.key == 'ArrowUp') {
      // Prevent up and down arrows from jumping the cursor to the beginning or end of the input
      evt.preventDefault()
      this.navigateChoices(evt.key == 'ArrowDown')
    }

    if(evt.keyCode == 32 || evt.key == 'Enter' || evt.key == "Tab") {
      this.chooseCurrentSelection(evt)
    }
  }

  /**
   * Navigate up and down within the search result list
   * @param [Boolean] down True if navigating down, false is up
   */
  navigateChoices(down) {
    const userSummaries = this.resultsTarget.querySelectorAll('.user-summary')
    if(userSummaries.length == 0) { return }

    let currentSelectionIndex = null
    for(let i=0; i < userSummaries.length; i++) {
      if(userSummaries[i].dataset.selected == 'true') {
        currentSelectionIndex = i
        delete userSummaries[i].dataset.selected
      }
    }

    let nextSelectionIndex = 0
    if(currentSelectionIndex == null) {
      nextSelectionIndex = 0
    } else if(down && currentSelectionIndex == userSummaries.length - 1) {
      nextSelectionIndex = 0
    } else if(!down && currentSelectionIndex == 0) {
      nextSelectionIndex = userSummaries.length - 1
    } else {
      if(down) {
        nextSelectionIndex = currentSelectionIndex + 1
      } else {
        nextSelectionIndex = currentSelectionIndex - 1
      }
    }

    userSummaries.item(nextSelectionIndex).dataset.selected = 'true'
  }

  /**
   * Select the current search result and populate the input field with it
   */
  chooseCurrentSelection(evt) {
    const userSummary = this.resultsTarget.querySelector('.user-summary[data-selected="true"]')
    if(userSummary) {
      evt.preventDefault()
      window.dispatchEvent(new CustomEvent('user.selection', {detail: { data: userSummary.dataset }}))
    }
  }

  /**
   * Display the search results container
   */
  openSearchResults() {
    // If the search field no longer has focus, showing results isn't
    // appropriate - the user has moved on.
    if(this.inputTarget != document.activeElement) { return }
    if(this.isSearchOpen) { return }

    this.data.set('searchOpen', 'true')
    this.resultsTarget.classList.remove('is-hidden')
  }

  /**
   * Hide the search results container
   */
  closeSearchResults() {
    if(!this.isSearchOpen) { return }

    setTimeout(() => {
      this.data.set('searchOpen', 'false')
      this.resultsTarget.classList.add('is-hidden')
    }, 50)
  }

  get isSearchOpen() {
    return this.data.get('searchOpen') == 'true'
  }

  /**
   * Respond to "user.selection" type events
   * @param evt
   */
  populateFromData(evt) {
    this.inputTarget.value = evt.detail.data.userUsername
    this.resize()
    this.selectNextFormControl()
  }

  /**
   * After a user is selected, select another form control automatically.
   * The next element is defined by ID via data-user-search-next.
   */
  selectNextFormControl() {
    if(this.data.get('next')) {
      // Prevent mobile Safari from hiding the keyboard while waiting for the timeout.
      this.inputTarget.focus()
      setTimeout(() => {
        document.getElementById(this.data.get('next')).focus()
      }, 150)
    }
  }
}
