import { Controller } from 'stimulus'

/**
 * Displays a preview of an image selected via a file input
 */
export default class extends Controller {
  static targets = ['file', 'preview', 'previewImage', 'name', 'clearButton']

  connect() {
    this.objectURL = null
  }

  preview() {
    if(this.fileTarget.files.length == 0) {
      this.clearPreview()
      return
    }

    if(this.hasPreviewImageTarget) {
      this.objectURL = URL.createObjectURL(this.fileTarget.files[0])
      this.previewImageTarget.src = this.objectURL
    }

    if(this.hasNameTarget) {
      this.nameTarget.innerText = this.fileTarget.files[0].name
    }

    this.data.set('hasFile', 'true')
  }

  clearPreview() {
    this.fileTarget.value = null
    this.data.set('hasFile', 'false')

    if(this.hasNameTarget) {
      this.nameTarget.innerText = null
    }

    if(this.hasPreviewImageTarget) {
      this.previewImageTarget.src = ''
      URL.revokeObjectURL(this.objectURL)
    }
  }
}
