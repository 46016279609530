import { Controller } from 'stimulus'

/**
 * Shows notifications to the user in classic toast style
 */
export default class extends Controller {
  connect() {
    // Prevent Turbolinks & browser cached notifications from showing again
    if(this.element.classList.contains('is-hidden')) {
      return
    }

    // Position the element to the correct position offscreen
    this.hide()

    this.showTimeout = setTimeout(() => {
      this.show()
    }, 100)

    if(this.data.get('close') != 'false') {
      const delay = this.data.get('delay') || 3500
      this.timeout = setTimeout(this.hide.bind(this), delay)
    }
  }

  disconnect() {
    clearTimeout(this.showTimeout)
    clearTimeout(this.timeout)
    this.element.classList.add('is-hidden')
  }

  show() {
    // Because the top style was computed and set on the element, it needs to be
    // reset before a class can affect it.
    this.element.style.top = null
    this.element.classList.add('is-showing')
  }

  hide() {
    // Dynamically set the off-screen offset based on the height of the notification
    const offset = this.element.getBoundingClientRect().height
    this.element.classList.remove('is-showing')
    this.element.style.top = `calc(${-(offset)}px - 1rem)`
  }
}
