import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'loader', 'error', 'success' ]


  clear(evt) {
    this.clearSuccess()
    this.clearError()
  }

  clearSuccess() {
    if(this.hasSuccessTarget) {
      this.successTarget.innerHTML = ''
    }
  }

  clearError() {
    if(this.hasErrorTarget) {
      this.errorTarget.innerHTML = ''
    }
  }

  renderSuccess(evt) {
    if(this.hasSuccessTarget) {
      this.successTarget.innerHTML = evt.detail[2].response
    }
  }

  renderError(evt) {
    if(this.hasErrorTarget) {
      this.errorTarget.innerHTML = evt.detail[2].response
    }
  }
}
