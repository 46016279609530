import { Controller } from 'stimulus'

/**
 * Toggle one or more classes on and off on the specified targets
 */
export default class extends Controller {
  static targets = [ 'toggle' ]

  initialize() {
    this.classList = []
    if(this.data.has('classes')) {
      this.classList = this.data.get('classes').split(' ')
    }
  }

  toggle() {
    this.toggleTargets.forEach((target) => {
      this.classList.forEach(c => target.classList.toggle(c))
    })
  }

  disconnect() {
    if(this.data.get('untoggle') != 'false') {
      this.toggleTargets.forEach(target => target.classList.remove(...this.classList))
    }
  }
}
