import { Controller } from 'stimulus'

/**
 * Handles sorting a list of cards between one column and two columns.
 * One column is used for mobile, two columns for everything else. This
 * is necessary to create a left-to-right "masonry" type flow for the cards,
 * which have varying heights. There's currently no HTML/CSS-only (aka non-JS)
 * way to solve for this. This approach assumes the cheer cards have been
 * rendered between two different columns and will interleave the two
 * columns together for mobile sizes.
 */
export default class extends Controller {
  static targets = [ 'column1', 'column2', 'column1Card', 'column2Card' ]

  connect() {
    this.resizeListener = this.resize.bind(this)
    window.addEventListener('resize', this.resizeListener, {passive: true})

    this.column1Cards = this.column1CardTargets
    this.column2Cards = this.column2CardTargets

    if(this.shouldSingleSort) {
      this.singleSort()
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeListener, {passive: true})
  }

  resize() {
    if(this.shouldSingleSort) {
      this.singleSort()
    } else if(this.shouldDoubleSort) {
      this.doubleSort()
    }
  }

  singleSort() {
    this.data.set('singleSorted', true)

    for(let i=0; i < this.column1Cards.length; i++) {
      if(this.column2Cards[i]) {
        this.column1Cards[i].after(this.column2Cards[i])
      }
    }

    // Hide the card columns on mobile to speed up flow when sorting on first load
    this.column1Target.classList.remove('is-hidden-mobile')
  }

  doubleSort() {
    this.data.set('singleSorted', false)
    for(let i=0; i < this.column2Cards.length; i++) {
      this.column2Target.appendChild(this.column2Cards[i])
    }
  }

  get isMobile() {
    return window.innerWidth < 768
  }

  get shouldSingleSort() {
    return this.isMobile && (this.data.get('singleSorted') == 'false' || !this.data.get('singleSorted'))
  }

  get shouldDoubleSort() {
    return !this.isMobile && this.data.get('singleSorted') == 'true'
  }
}
